<template>
  <div class="div">
    <b-modal :active.sync="showModal" :width="740" scroll="keep">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <form v-on:submit.prevent="$emit('functionByModal', newEntries)">
              <b-field
                :label="input.type != 'file' && input.type != 'checkbox' ? input.label : ''"
                v-for="(input, index) in inputs"
                :key="input.model"
              >
                <b-input
                  v-if="input.required && (input.type === 'text' || input.type === 'textarea' || input.type === 'number')"
                  :type="input.type"
                  v-model.trim="newEntries[input.model]"
                  required
                  minlength="3"
                ></b-input>

                <b-input
                  v-if="!input.required && (input.type === 'text' || input.type === 'textarea' || input.type === 'number')"
                  :type="input.type"
                  v-model.trim="newEntries[input.model]"
                ></b-input>

                <b-checkbox
                  v-if="input.type == 'checkbox'"
                  v-model="newEntries[input.model]"
                >{{input.label}}</b-checkbox>

                <b-select
                  v-if="input.type == 'select'"
                  v-model="newEntries[input.model]"
                  :placeholder="input.label"
                >
                  <option :value="v._id" v-for="v in input.content" :key="v._id">{{v.name}}</option>
                  <option :value="null">Nenhum</option>
                </b-select>

                <div class="box" v-if="input.type == 'box'">
                  <div v-show="input.content && input.content[0]" v-for="v in input.content" :key="v._id">
                    <div >
                      <b class="m-r-sm">{{v.user.name}}: </b>
                      <span>{{v.message}}</span>
                    </div>
                    <span><b>Data:</b> {{new Date(v.createdAt).toLocaleDateString("pt-BR") + ' '}}</span>
                    <span> {{new Date(v.createdAt).toLocaleTimeString("pt-BR")}}</span>
                    <hr />
                  </div>
                  <p v-show="!input.content || !input.content[0]">Sem respostas</p>
                </div>

                <div v-if="input.type == 'file'">
                  <label class="label custom">{{ input.label }}</label>
                  <img
                    v-if="newEntries[input.model] && typeof newEntries[input.model] == 'string'"
                    :src="newEntries[input.model]"
                    alt="Imagem upada"
                  />
                  <b-button
                    v-if="newEntries[input.model] && typeof newEntries[input.model] == 'string'"
                    @click="removeArrayUpload(input)"
                    type="is-text"
                  >Remover</b-button>
                  <a
                    v-if="newEntries[input.model] && typeof newEntries[input.model] == 'string'"
                    class="button is-text"
                    :href="newEntries[input.model]"
                  >Baixar</a>

                  <div v-if="newEntries[input.model] && typeof newEntries[input.model] != 'string'">
                    <div v-for="(doc, index) in newEntries[input.model]" :key="index">
                      <span>{{doc.slice(42)}}</span>
                      <b-button @click="removeArrayUpload(input, index)" type="is-text">Remover</b-button>
                      <a class="button is-text" :href="newEntries[input.model]">Baixar</a>
                    </div>
                  </div>

                  <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="true"></b-loading>

                  <div class="is-flex">
                    <b-upload
                      v-model="newEntries[input.model+'Up']"
                      @input="upload(newEntries[input.model+'Up'], index, input)"
                    >
                      <a class="button is-primary">
                        <b-icon icon="upload"></b-icon>
                        <span>Adicionar Arquivo</span>
                      </a>
                    </b-upload>
                  </div>
                </div>
              </b-field>
              <button class="button is-primary">Salvar</button>
            </form>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Axios from "axios";

export default {
  name: "cursosAdmin",
  props: {
    inputs: Array,
    active: Boolean,
    clear: Boolean
  },
  data() {
    return {
      newEntries: [],
      showModal: false,
      isLoading: false
    };
  },
  methods: {
    upload(data, index, input) {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
      }, 10 * 1000);
      const fd = new FormData();
      fd.append("file", data, data.name);
      Axios.post(`/v1/uploads/`, fd)
        .then(res => {
          if (data.type.includes("image")) {
            this.inputs[index][input.model] = res.data.location;
            this.inputs[index][input.model + "Up"] = res.data.location;
            this.newEntries[input.model] = res.data.location;
            this.isLoading = false;
          } else {
            let temp = this.newEntries[input.model]
              ? this.newEntries[input.model]
              : [];
            temp.push(res.data.location);
            this.newEntries[input.model] = temp;
            this.isLoading = false;
          }
          this.$forceUpdate(this.newEntries[input.model]);
        })
        // eslint-disable-next-line
        .catch(err => {
          console.error(err);
          this.isLoading = false;
          this.$buefy.dialog.alert("Erro ao enviar arquivo!");
        });
    },
    removeArrayUpload(data, index) {
      if (index || index === 0) {
        let temp = this.newEntries[data.model];
        temp = temp.filter(title => title !== temp[index]);
        this.newEntries[data.model] = temp;
      } else {
        this.newEntries[data.model] = null;
      }
      this.$forceUpdate(this.newEntries[data.model]);
    }
  },
  watch: {
    showModal(showModal) {
      if (!showModal) {
        this.$emit("close");
      }
    },
    active(active) {
      if (active) {
        this.showModal = true;
      } else {
        this.showModal = false;
      }
    },
    inputs(inputs) {
      this.newEntries = [];
      for (let input of inputs) {
        if (input[input.model]) {
          this.newEntries[input.model] = input[input.model];
        }
      }
    },
    clear() {
      this.newEntries = [];
    }
  }
};
</script>
<style lang="scss" scoped>
img {
  width: 4rem;
  height: 4rem;
}
</style>
